button {
    color: white;
}

a.login {
    border-radius: 6px;
    border: none;
    background: rgba(255, 255, 255, 0.75);
    font-size: 20px;
    text-decoration: none;
    color: black;
    text-decoration: none;
    display: block;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    transition: background 250ms;
        
    &:visited {
        color: black;
    }

    &:hover {
        background: rgba(255, 255, 255, 1);
    }
}

.home-page {
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    border-radius: 6px;
    flex-direction: column;
    padding: 40px;
    color: black;

    p {
        color: black;
    }

    .upload-container {
        flex: 1;
        margin-bottom: 30px;
        button {
            width: 100%;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        li{
            text-align: left;
            list-style-type: none;
            margin-bottom: 40px;

            code {
                color: white;
                background-color: rgba(0, 0, 0, 0.65);
                display: inline-block;
                padding: 5px 10px;
                border-radius: 3px;
            }
            span {
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }
}

p.copied {
    text-align: center;
    color: black;
    background-color: white;
    padding: 5px 10px;
}

.streamKeyContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    input {
        margin: 0 0 0 10px;
        background-color: rgba(0, 0, 0, 0.65);

        padding: 5px 10px;
        border-radius: 3px;
        border: none;
        outline: none;

        color: white;
    }

    button {
        width: 60px;
        height: 25px;
        margin: 0 0 0 20px;

        border: none;
        border-radius: 6px;
        background-color: #FFFFFFBF;

        display: block;
        transition: all ease 250ms;

        color: black;
        font-size: 16px;
        font-weight: 500;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        text-decoration: none;
        text-decoration: none;
        text-align: center;
    }
}